import $ from 'jquery';
import mapboxgl from 'mapbox-gl';

import '../images/map-point.svg';
import 'bootstrap/js/dist/carousel';

// Remove no-js class
document.documentElement.className = 'js';

// Set height of hero section
function setHeroHeight() {
	const height = window.innerHeight;
	$('.hero')
		.height(height);
}

// ScrollMagic controller
const controller = new ScrollMagic.Controller();

// Pin Hero section
const pinHero = new ScrollMagic.Scene({
	triggerElement: '.hero',
	triggerHook: 0,
});

if (window.innerWidth > 991) {
	pinHero
		.setPin('.hero', { pushFollowers: false })
		.addTo(controller);

	setHeroHeight();
}

// Change Header's class
const changeClass = new ScrollMagic.Scene({
	triggerElement: '.main',
	triggerHook: 0.08
});

changeClass.addTo(controller);

changeClass.on('enter', function () {
	$('.header .btn')
		.toggleClass('btn-primary btn-light');
	$('.header')
		.addClass('header--white');
});

changeClass.on('leave', function () {
	$('.header .btn')
		.toggleClass('btn-primary btn-light');
	$('.header')
		.removeClass('header--white');
});

// Social hover
$('.hero__social__link')
	.hover(function () {
		$(this)
			.next()
			.toggleClass('show');
	});

// Map
mapboxgl.accessToken = 'pk.eyJ1IjoiamVuaWN6ZWNoIiwiYSI6ImNqd3FrNDJkbjFob2szeWxueXBrb3k1eHoifQ.Jlt394exsogEdMwka59bxQ';

const map = new mapboxgl.Map({
	container: 'contact-map',
	style: 'mapbox://styles/jeniczech/ckbuqs7360hor1il3lyabgy1m',
	center: [15.7102372, 50.3346572],
	zoom: 14,
});

const geojson = {
	type: 'FeatureCollection',
	features: [{
		type: 'Feature',
		geometry: {
			type: 'Point',
			coordinates: [15.7102372, 50.3346572]
		}
	}]
};

geojson.features.forEach(function (marker) {
	const el = document.createElement('div');
	const point = document.createElement('img');
	point.setAttribute('src', 'static/images/map-point.svg');
	el.className = 'map__point';
	el.appendChild(point);

	new mapboxgl.Marker(el)
		.setLngLat(marker.geometry.coordinates)
		.addTo(map);
});

map.addControl(new mapboxgl.NavigationControl());

map.on('load', function () {
	map.resize();
});

if (window.innerWidth < 576) {
	map.scrollZoom.disable();
	map.dragPan.disable();
}

//Scroll to main section
$('.hero__scroll')
	.click(function () {
		$('html, body')
			.animate({
				scrollTop: ($('.main')
					.offset().top - 30)
			}, 500);
	});

//Scroll to contact
$('.js-scrollToContact')
	.click(function () {
		$('html, body')
			.animate({
				scrollTop: ($('.main__contact')
					.offset().top - 80)
			}, 500);
	});



